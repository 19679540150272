package components

import StoreContext
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import support.*
import techla.base.Date
import techla.reservation.Reservation
import techla.reservation.Resource
import web.cssom.*
import web.dom.Element
import web.html.ButtonType

external interface HRoomProps : Props {
    var room: Resource
    var reservations: List<Reservation>
    var onBook: suspend (Date, Date, Resource) -> Unit
}

private fun hourMinuteToDate(date: Date, hourMinute: String): Date? {
    if (hourMinute.isEmpty()) return null
    val (hour, minute) = hourMinute.split(":")
    return Date.dateAt(date.dateTime.year, date.dateTime.monthNumber, date.dateTime.dayOfMonth, hour.toInt(), minute.toInt())
}

val HRoom = FC<HRoomProps> { props ->
    val (store, _) = useContext(StoreContext)
    val reservableTimes = (8..22).flatMap { hour ->
        val strHour = "$hour".padStart(2, '0')
        listOf("$strHour:00", "$strHour:30")
    }

    var startsAt: String by useState("")
    var endsAt: String by useState("")
    var isOpen by useState(false)

    val disabled = (startsAt.isBlank()) || (endsAt.isBlank())

    val location = props.room.tags.filterIsInstance<Resource.Tag.Location>().firstOrNull()
    val capacity = props.room.tags.filterIsInstance<Resource.Tag.Capacity>().firstOrNull()
    val equipment = props.room.tags.filterIsInstance<Resource.Tag.Equipment>()
    val reservations = props.reservations.filter { it.startsAt.toYearMonthDayFormat == store.date.toYearMonthDayFormat }
        .sortedBy { it.startsAt }

    fun onClick(event: MouseEvent<Element, *>) {
        if (!disabled) {
            val start = hourMinuteToDate(store.date, startsAt)
            val end = hourMinuteToDate(store.date, endsAt)

            MainScope().launch {
                props.onBook(start!!, end!!, props.room)
                    .also { isOpen = false }
            }

            startsAt = ""
            endsAt = ""
        }

    }

    Stack {
        Typography {
            css {
                textTransform = TextTransform.uppercase
            }
            variant = TypographyVariant.h2
            +props.room.name
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                flexWrap = FlexWrap.wrap
            }

            Stack {
                spacing = responsive(1)
                direction = responsive(StackDirection.row)
                sx {
                    marginTop = 8.px
                }

                Chip {
                    label = ReactNode("${location?.building}, våning ${location?.floor}")
                    variant = ChipVariant.filled
                    color = ChipColor.secondary
                }

                Chip {
                    label = ReactNode("${capacity?.seats} platser")
                    variant = ChipVariant.filled
                    color = ChipColor.secondary
                }
            }

            Stack {
                spacing = responsive(1)
                direction = responsive(StackDirection.row)
                sx {
                    marginTop = 8.px
                }

                equipment.map { equipment ->
                    Chip {
                        label = ReactNode(equipment.name)
                        variant = ChipVariant.outlined
                        color = ChipColor.default
                    }
                }
            }
        }

        div {
            css {
                marginTop = 8.px
            }
            if (reservations.isEmpty()) {
                div {
                    css {
                        border = Border(1.px, LineStyle.solid)
                        borderColor = rgb(76, 76, 76)
                    }

                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center
                        css {
                            padding = Padding(20.px, 23.px, 20.px, 23.px)
                        }

                        +"No bookings this day"
                    }
                }
            }
            reservations.map { reservation ->
                div {
                    css {
                        borderLeft = Border(1.px, LineStyle.solid)
                        borderRight = Border(1.px, LineStyle.solid)
                        borderBottom = Border(1.px, LineStyle.solid)
                        borderColor = rgb(76, 76, 76)
                        firstChild {
                            borderTop = Border(1.px, LineStyle.solid)
                            borderColor = rgb(76, 76, 76)
                        }
                    }

                    Typography {
                        variant = TypographyVariant.body1
                        css {
                            padding = Padding(20.px, 23.px, 20.px, 23.px)
                        }
                        +"${reservation.startsAt.toHourMinuteFormat}-${reservation.endsAt.toHourMinuteFormat} ${reservation.name}"
                    }
                }
            }

            div {
                css {
                    backgroundColor = Color(HetchColors.gray)
                    padding = Padding(16.px, 0.px, 16.px, 0.px)
                }

                Container {
                    maxWidth = "xs"

                    HForm {
                        Stack {
                            direction = responsive(StackDirection.row)
                            spacing = responsive(2)
                            sx {
                                justifyContent = JustifyContent.center
                            }

                            HSelect {
                                withLabel = false
                                fullWidth = false
                                value = startsAt
                                onChange = { value -> startsAt = value ?: "" }

                                design = DesignSystem.Input.Select(
                                    name = "from",
                                    value = "",
                                    label = "From",
                                    options = listOf(
                                        DesignSystem.Option(
                                            title = "From", value = ""
                                        )
                                    ) + reservableTimes.map { DesignSystem.Option(title = it, value = it) }
                                )
                            }

                            HSelect {
                                withLabel = false
                                fullWidth = false
                                value = endsAt
                                onChange = { value -> endsAt = value ?: "" }

                                design = DesignSystem.Input.Select(
                                    name = "to",
                                    value = "",
                                    label = "To",
                                    options = listOf(
                                        DesignSystem.Option(
                                            title = "To", value = ""
                                        )
                                    ) + reservableTimes.map { DesignSystem.Option(title = it, value = it) }
                                )
                            }

                            HButton {
                                design =
                                    DesignSystem.Button(type = ButtonType.button, text = "Book", disabled = disabled)
                                onClick = { _ -> isOpen = true }
                            }
                        }
                    }
                }
            }
        }
    }

    HDialog {
        open = isOpen
        onClose = { isOpen = false }
        title = "ARE YOU SURE YOU WANT TO BOOK"

        Typography {
            variant = TypographyVariant.h2
            align = TypographyAlign.center

            +props.room.name.uppercase()
        }

        Typography {
            variant = TypographyVariant.body1
            align = TypographyAlign.center

            val start = hourMinuteToDate(store.date, startsAt)
            val end = hourMinuteToDate(store.date, endsAt)

            if (start != null && end != null) {
                +"${start.toYearMonthDayFormat} ${start.toHourMinuteFormat}-${end.toHourMinuteFormat}"
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.center
            }
            HButton {
                design = DesignSystem.Button(type = ButtonType.button, text = "YES, BOOK!")
                onClick = ::onClick
            }
        }
    }
}