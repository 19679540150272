package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.icons.material.CalendarMonth
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.useContext
import react.useState
import screens.BookScreen
import support.*
import techla.base.Date
import techla.base.Identifier
import techla.base.fromISOString
import techla.reservation.Reservation
import techla.reservation.Resource
import web.cssom.AlignItems
import web.cssom.JustifyContent
import web.cssom.px
import web.window.WindowTarget


val Book = FC<Props> {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(BookScreen.ViewModel.None as BookScreen.ViewModel)
    val navigate = useNavigate()

    var deleting: Reservation? by useState(null)

    fun showReservations() = MainScope().launch {
        BookScreen.myReservations(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun onClose() {
        MainScope().launch {
            BookScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    fun onDateFilter(value: String?) = MainScope().launch {
        val date = Date.fromISOString("${value}T00:00:00.000Z") ?: Date.now()
        BookScreen.changeDate(sceneInputOf(store, viewModel), date = date).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun onFloorFilter(value: String?) = MainScope().launch {
        BookScreen.filter(sceneInputOf(store, viewModel), floor = value?.toIntOrNull(), capacity = store.capacity)
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    fun onCapacityFilter(value: String?) = MainScope().launch {
        BookScreen.filter(sceneInputOf(store, viewModel), floor = store.floor, capacity = value?.toIntOrNull())
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    suspend fun onBook(startsAt: Date, endsAt: Date, room: Resource) {
        BookScreen.book(sceneInputOf(store, viewModel), startsAt = startsAt, endsAt = endsAt, room = room)
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    fun onDelete(id: Identifier<Reservation>) = MainScope().launch {
        BookScreen.delete(sceneInputOf(store, viewModel), id = id).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        window.scrollTo(0.0, 0.0)
        when (viewModel) {
            is BookScreen.ViewModel.None -> BookScreen.load(sceneInputOf(store, viewModel))
                .also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is BookScreen.ViewModel.Ready -> {}
            is BookScreen.ViewModel.ShowingReservations -> {}
            is BookScreen.ViewModel.Deleting -> {}
            is BookScreen.ViewModel.Deleted -> {
                deleting = null
            }

            is BookScreen.ViewModel.Booking -> {}
            is BookScreen.ViewModel.Booked -> {}
            is BookScreen.ViewModel.ValidationError -> {}
            is BookScreen.ViewModel.Failed -> navigate("/")
        }
    }

    main {
        HNavbar {}
        if (viewModel is BookScreen.ViewModel.Failed) {
            Failure { design = viewModel.failure; onClick = { navigate("/") } }
        } else {
            Container {
                maxWidth = "lg"
                sx {
                    paddingBottom = 48.px
                }

                Stack {
                    spacing = responsive(6)

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                        }

                        Typography {
                            align = TypographyAlign.left
                            variant = TypographyVariant.h1

                            +"ROOMS"
                        }

                        Badge {
                            val numReservations = store.myReservations.size
                            badgeContent = ReactNode(numReservations)
                            ariaLabel = "You have $numReservations active bookings"
                            color = BadgeColor.secondary
                            HButton {
                                design = viewModel.openReservations
                                onClick = { _ -> showReservations() }

                                CalendarMonth {
                                    css {
                                        paddingRight = 4.px
                                    }
                                }
                            }
                        }
                    }

                    Stack {
                        spacing = responsive(4)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                        }

                        direction = responsive(
                            Breakpoint.sm to StackDirection.row,
                            Breakpoint.xl to StackDirection.column
                        )

                        HInput {
                            design = viewModel.filterDate
                            fullWidth = true
                            onChange = { value -> onDateFilter(value) }
                        }

                        HSelect {
                            withLabel = true
                            fullWidth = true
                            value = store.floor?.toString()
                            design = viewModel.filterFloor
                            onChange = { value -> onFloorFilter(value) }
                        }

                        HSelect {
                            withLabel = true
                            fullWidth = true
                            value = store.capacity?.toString()
                            design = viewModel.filterCapacity
                            onChange = { value -> onCapacityFilter(value) }
                        }
                    }

                    Stack {
                        spacing = responsive(1)

                        Typography {
                            variant = TypographyVariant.body1
                            +"Floorplan maps: "
                            Link {
                                color = HetchColors.green
                                target = WindowTarget._blank
                                href = "https://noboto.io/dev/hetch/skiss_plan_0.pdf"
                                +"Plan 0, "
                            }
                            Link {
                                color = HetchColors.green
                                target = WindowTarget._blank
                                href = "https://noboto.io/dev/hetch/skiss_plan_1.pdf"
                                +"Plan 1, "
                            }
                            Link {
                                color = HetchColors.green
                                target = WindowTarget._blank
                                href = "https://noboto.io/dev/hetch/skiss_plan_2.pdf"
                                +"Plan 2"
                            }
                        }

                        Typography {
                            variant = TypographyVariant.body1
                            Link {
                                color = HetchColors.green
                                target = WindowTarget._blank
                                href = "https://rooms.hetch.se"
                                +"More about the Rooms"
                            }
                        }
                    }

                    Stack {
                        spacing = responsive(4)
                        store.rooms.map { room ->
                            HRoom {
                                this.room = room
                                this.reservations = store.reservations.filter { it.resource == room }
                                this.onBook = ::onBook
                            }
                        }
                    }
                }

                HDialog {
                    open = viewModel is BookScreen.ViewModel.ShowingReservations
                    onClose = ::onClose
                    title = "MY BOOKINGS (${store.myReservations.size})"

                    if (store.myReservations.isEmpty()) {
                        Typography {
                            variant = TypographyVariant.body1
                            align = TypographyAlign.center

                            +"No bookings registered on you"
                        }
                    }

                    store.myReservations.sortedBy { it.startsAt }.map { reservation ->
                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                                alignItems = AlignItems.center
                            }

                            div {
                                Typography {
                                    variant = TypographyVariant.body1
                                    +"${reservation.resource.name.uppercase()} - ${reservation.startsAt.toYearMonthDayFormat}"
                                }
                                Typography {
                                    variant = TypographyVariant.body1
                                    +"${reservation.startsAt.toHourMinuteFormat}-${reservation.endsAt.toHourMinuteFormat}"
                                }
                            }

                            HButton {
                                design = viewModel.deleteReservation
                                onClick = { _ -> onDelete(reservation.id) }
                                onClick = { _ -> deleting = reservation }
                            }
                        }
                    }
                }

                HDialog {
                    open = viewModel is BookScreen.ViewModel.Deleted
                    onClose = ::onClose
                    title = "BOOKING REMOVED!"

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.ok
                            onClick = { _ -> onClose() }
                        }
                    }
                }

                HDialog {
                    open = viewModel is BookScreen.ViewModel.Booked
                    onClose = ::onClose
                    title = "ROOM SUCCESSFULLY BOOKED!"


                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.ok
                            onClick = { _ -> onClose() }
                        }
                    }
                }

                HDialog {
                    open = deleting != null
                    onClose = { deleting = null }
                    title = "ARE YOU SURE YOU WANT TO REMOVE"

                    Typography {
                        variant = TypographyVariant.h2
                        align = TypographyAlign.center

                        +(deleting?.resource?.name?.uppercase() ?: "")
                    }

                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center

                        +(deleting?.let { "${it.startsAt.toYearMonthDayFormat} ${it.startsAt.toHourMinuteFormat}-${it.endsAt.toHourMinuteFormat}" }
                            ?: "")
                    }


                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.confirmReservationDelete
                            onClick = { _ ->
                                deleting?.let { onDelete(it.id) }
                            }
                        }
                    }
                }

                HDialog {
                    open = viewModel is BookScreen.ViewModel.ValidationError
                    onClose = ::onClose
                    title = "BOOKING FAILED"

                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center

                        if (viewModel is BookScreen.ViewModel.ValidationError) {
                            +viewModel.warnings.firstOrNull()?.message
                        }
                    }

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.center
                        }
                        HButton {
                            design = viewModel.ok
                            onClick = { _ -> onClose() }
                        }
                    }
                }
            }
        }
    }
}