package screens

import services.createReservation
import services.deleteReservation
import services.findReservations
import services.listResources
import support.*
import techla.base.*
import techla.reservation.Reservation
import techla.reservation.Resource
import web.html.ButtonType

private val Int.toOption: DesignSystem.Option
    get() = DesignSystem.Option(title = "$this", value = "$this")

object BookScreen {
    sealed class ViewModel(
        val openReservations: DesignSystem.Button = DesignSystem.Button(type = ButtonType.button, text = "My bookings"),
        val confirmReservationDelete: DesignSystem.Button = DesignSystem.Button(
            type = ButtonType.button, text = "YES, REMOVE!", danger = true
        ),
        val ok: DesignSystem.Button = DesignSystem.Button(type = ButtonType.button, text = "OK"),
        val deleteReservation: DesignSystem.Button = DesignSystem.Button(
            type = ButtonType.button, text = "REMOVE", danger = true
        ),
        val filterDate: DesignSystem.Input.Date = DesignSystem.Input.Date(
            name = "date",
            label = "Date",
            value = Date.now().toYearMonthDayFormat,
        ),
        val filterCapacity: DesignSystem.Input.Select = DesignSystem.Input.Select(name = "capacity",
            label = "Capacity",
            value = "",
            options = listOf(DesignSystem.Option(title = "All", "")) + (1..12).map { it.toOption }),
        val filterFloor: DesignSystem.Input.Select = DesignSystem.Input.Select(name = "floor",
            label = "Floor",
            value = "",
            options = listOf(DesignSystem.Option(title = "All", "")) + (0..2).map { it.toOption }),
    ) {
        object None : ViewModel()
        object Ready : ViewModel()
        object ShowingReservations : ViewModel()
        object Deleted : ViewModel()
        object Deleting : ViewModel()
        object Booking : ViewModel()
        object Booked : ViewModel()
        data class Failed(
            val failure: DesignSystem.Failure,
        ) : ViewModel()

        data class ValidationError(val warnings: List<Warning>) : ViewModel()

        fun ready() = Ready

        fun deleted() = Deleted
        fun booked() = Booked

        fun showingReservations() = ShowingReservations

        fun failed(failure: Either<List<Warning>, Throwable>, isSignedIn: Boolean): ViewModel =
            when (failure) {
                is Either.Left -> ValidationError(warnings = failure.value)
                is Either.Right -> Failed(failure = failure(failure, isSignedIn))
            }

    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result, store.isSignedIn))

    suspend fun myReservations(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf<ViewModel>(viewModel.showingReservations(), emptyList())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, _) = scene
        //if (!store.isSignedIn) return scene.failed(Either.Right(TechlaError.Unauthorized("Must be signed in to manage bookings")))
        return filter(scene, floor = store.floor, capacity = store.capacity)
    }

    suspend fun book(
        scene: Scene.Input<ViewModel>, startsAt: Date, endsAt: Date, room: Resource
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val create = Reservation.Create(
            resource = room.key,
            name = "${store.me?.firstName}, ${store.me?.lastName}",
            startsAt = startsAt,
            endsAt = endsAt,
        )

        return store.createReservation(create = create).map { (actions, _) ->
            sceneOf<ViewModel>(viewModel.booked(), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<Reservation>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.deleteReservation(id).map { (actions, _) ->
            sceneOf<ViewModel>(viewModel.deleted(), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun changeDate(scene: Scene.Input<ViewModel>, date: Date): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(true).noActions().flatMap { (actions, _) ->
            val action = Store.Action.ChangeDate(date = date)
            store.reduce(action).findReservations().accumulate(action)
        }.map { (actions, reservations) ->
            val action = Store.Action.ListReservations(reservations = reservations)
            sceneOf<ViewModel>(viewModel.ready(), actions + action)
        }.failed { scene.failed(result = it) }
    }

    suspend fun filter(scene: Scene.Input<ViewModel>, floor: Int?, capacity: Int?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.listResources().zip(store.findReservations()).fold(
            { (resources, reservations) ->
                val filteredRooms = resources.second.filter { room ->
                    val _floor = room.tags.filterIsInstance<Resource.Tag.Location>().firstOrNull()?.floor
                    val _capacity = room.tags.filterIsInstance<Resource.Tag.Capacity>().firstOrNull()?.seats

                    (floor == null || _floor == floor) && (capacity == null || _capacity!! >= capacity)
                }
                val actions = listOf(
                    Store.Action.ListRooms(rooms = resources.second),
                    Store.Action.FilterRooms(rooms = filteredRooms, floor = floor, capacity = capacity),
                    Store.Action.ListReservations(reservations = reservations.second),
                )
                sceneOf<ViewModel>(viewModel.ready(), actions)
            },
            {
                scene.failed(result = it)
            }
        )
    }
}
